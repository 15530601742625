/* For Amplify Authenticator */
:root {
  --primary-color: #5e7480;
  --primary-tint: #3c3c3c;
  --primary-shade: #3c3c3c;
  --secondary-color: #3c3c3c;

  --amplify-primary-color: var(--primary-color);
  --amplify-primary-tint: var(--primary-tint);
  --amplify-primary-shade: var(--primary-shade);
}